import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "app"
};
import { useWindowSize } from '@vueuse/core';
import { watch } from 'vue';
export default {
  __name: 'App',

  setup(__props) {
    const {
      width
    } = useWindowSize();
    watch(width, (nVal, oVal) => {
      if (oVal < 768 && nVal < 768) return;

      if (width.value < 768 || oVal < 768 && nVal > 768) {
        window.location.reload();
      }
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view, null, {
        default: _withCtx(({
          Component,
          route
        }) => [(_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: route.name === 'Showroom' ? route.name : route.fullPath
        })) : _createCommentVNode("", true)], 1024)), !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: route.fullPath
        })) : _createCommentVNode("", true)]),
        _: 1
      })]);
    };
  }

};