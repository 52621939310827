import axios from 'axios'
import CryptoJS from 'crypto-js/crypto-js'

export function newRequest(config) {
  // 1.创建axios的实例

  var timestamp = (new Date()).valueOf()
  // console.log(config.signParams)
  var sign = signSHA256Header(config.signParams, timestamp, timestamp)

  const instance = axios.create({
    // baseURL: 'http://test.mirror-exhibition.com',
    baseURL: 'https://plan.bodoai.com/api',
    // baseURL: 'https://2021.gafa-vad.com',
    // baseURL: 'http://localhost:8081',
    timeout: 5000,
    headers: {
      'request-sign': sign,
      'request-timestamp': timestamp,
      'request-nonce': timestamp
    }
  })

  // 2.axios的拦截器
  instance.interceptors.request.use(
    function(config) {
      // config的所有请求信息，判断是否登录状态
      return config
    }, error => {
      return Promise.reject(error)
    })
  instance.interceptors.response.use(response => {
    // 响应拦截：拦截后需要返回response.data
    return response.data
  }, error => {
    // const redireUrl = window.location.href
    // scope为snsapi_base，是静默授权，需要关注， 为snsapi_userinfo，不需要关注，有弹窗问是否授权
    // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxed9c9df916bee848&redirect_uri=${redireUrl}&response_type=code&scope=snsapi_base&state=STATE&connect_redirect=1#wechat_redirect`
    return Promise.reject(error)
  })

  // 3.发送真正的网络请求，返回的就是promise
  return instance(config)
}

export function signSHA256Header(params = {}, timestamp, nonce) {
  var orderParams = {}
  Object.keys(params).sort().forEach(function(key) {
    orderParams[key] = params[key]
  })

  var textStr = ''
  Object.keys(orderParams).forEach(function(key) {
    textStr = textStr + key + '=' + orderParams[key] + '&'
  })

  textStr += 'timestamp=' + timestamp + '&nonce=' + nonce
  // console.log("text:",textStr)
  return CryptoJS.HmacSHA256(textStr, 'Rz5LvRJnEgJvYmTzdqsv5TDacT48GLyJ').toString()
}
