/* eslint-disable no-multi-spaces */
import { createRouter, createWebHistory } from 'vue-router'
const tool              = () => import(/* webpackChunkName: "tool" */ '@/views/tool.vue')
const Welcome            = () => import(/* webpackChunkName: "welcome" */ '@/views/Welcome.vue')
const pcHome            = () => import(/* webpackChunkName: "pcHome" */ '@/Pc/Index.vue')
const pcWorks           = () => import(/* webpackChunkName: "pcWorks" */ '@/Pc/Works/index.vue')
const pcWorkDetail      = () => import(/* webpackChunkName: "pcWorkDetail" */ '@/Pc/WorkDetail/index.vue')
const mbHome            = () => import(/* webpackChunkName: "mbHome" */ '@/Mb/Index.vue')
const mbWorks           = () => import(/* webpackChunkName: "mbWorks" */ '@/Mb/Works/index.vue')
const mbWorkDetail      = () => import(/* webpackChunkName: "mbWorkDetail" */ '@/Mb/WorkDetail/index.vue')
const admire01zhanjiang = () => import(/* webpackChunkName: "admire01zhanjiang" */ '@/museumviews/admire01zhanjiang.vue')
const MuseumTool        = () => import(/* webpackChunkName: "MuseumTool" */ '@/museumviews/MuseumTool.vue')
const Mercury           = () => import(/* webpackChunkName: "Mercury" */ '@/museumviews/Mercury.vue')
const taiLake = () => import(/* webpackChunkName: "MuseumSee" */ '@/museumviews/taiLake.vue')

// const routerHistory = createWebHistory()
const router = createRouter({
  history: createWebHistory(window.location.pathname.split('/')[1]),

  routes: [
    {

      path: '/tool',
      name: 'tool',
      component: tool
    },
    {
      // 电脑端首页
      path: '/Welcome',
      name: 'Welcome',
      component: Welcome,
      meta: {
        title: '首页'
      }
    },
    {
      // 电脑端首页
      path: '/pc',
      name: 'pcHome',
      component: pcHome,
      meta: {
        title: '首页'
      }
    },
    {
      // pc端作品列表页
      name: 'pcWorks',
      path: '/pc/works/',
      component: pcWorks,
      meta: {
        title: '作品列表',
        keepAlive: true
      }
    },
    {
      // 电脑端作品详情页
      path: '/pc/workDetail/:id',
      name: 'pcWorkDetail',
      component: pcWorkDetail,
      meta: {
        title: '作品详情'
      }
    },
    {
      // 移动端首页
      path: '/mb',
      name: 'mbHome',
      component: mbHome,
      meta: {
        title: '首页'
      }
    },
    {
      // 移动端作品列表页
      path: '/mb/works/',
      name: 'mbWorks',
      component: mbWorks,
      meta: {
        title: '作品列表',
        keepAlive: true
      }
    },
    {
      // 移动端作品详情页
      path: '/mb/workDetail/:id',
      name: 'mbWorkDetail',
      component: mbWorkDetail,
      meta: {
        title: '作品详情'
      }
    },
    {
      // 展厅 湛江
      path: '/admire01zhanjiang/:id',
      name: 'admire01zhanjiang',
      component: admire01zhanjiang,
      meta: {
        keepAlive: true,
        title: 'admire01zhanjiang'
      }
    },
    {
      // 展厅 水星
      path: '/Mercury/:id',
      name: 'Mercury',
      component: Mercury,
      meta: {
        keepAlive: true,             title: 'Mercury'
      }
    },
    {
      // 展厅 查看工具
      path: '/MuseumTool/:id',
      name: 'MuseumTool',
      component: MuseumTool,
      meta: {
        keepAlive: true,
        title: 'MuseumTool'
      }
    },
    {
      // 展厅 模型查看工具
      path: '/taiLake/:id',
      name: 'Showroom',
      component: taiLake,
      meta: {
        keepAlive: true,
        title: 'tailake'
      }
    }
  ]
})
export default router
