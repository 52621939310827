import router from './index'
import store from '@/store'

import { isWeiXin, isMobile, setWXShare } from '@/utils/utils.js'
import { useTitle } from '@vueuse/core'

router.beforeEach(async(to, from, next) => {
  if (store.state.exhibition?.config) {
    useTitle(store.state.exhibition.config.baseConfig.sign)
  }
  // 获取url上的parma参数用来获取展览请求
  const urlroute = window.location.pathname.toLowerCase().split('/')
  if (store.state.exhibitionDomainName !== urlroute[1]) {
    store.commit('setExhibitionDomainName', urlroute[1])
  }
  // 作品预览、获取展览文字数据
  let path = to.path
  const query = to.query

  if (path.indexOf('/workPreview') > -1 || path.indexOf('/GetText') > -1) {
    if (isWeiXin() || isMobile()) {
      if (path === '/') {
        next({ path: '/mb', query })
      } else if (path.indexOf('/pc') !== -1) {
        path = path.replace('/pc', '/mb')
        next({ path: path, query })
      } else {
        next()
      }
    } else {
      if (path === '/') {
        next({ path: '/pc' })
      } else if (path.indexOf('/mb') !== -1) {
        path = path.replace('/mb', '/pc')

        next({ path: path, query })
      } else {
        next({ path: path, query })
      }
    }
  } else {
    // 展览上线时间判断,存储展览数据到vuex
    if (store.state.exhibition === null) {
      // eslint-disable-next-line prefer-const
      let [exhibition, time] = await store.dispatch('getExhibitionInfo')
      // setWXShare()
      // console.log('res', exhibition)

      var onlineTime = exhibition.onlineTime
      var deadline = exhibition.deadline

      // console.log(onlineTime, '上线时间')
      onlineTime = new Date(onlineTime).getTime() / 1000

      // console.log(deadline, '展览期限')
      deadline = new Date(deadline).getTime() / 1000

      // console.log(time, '现在时间')
      time = new Date(time).getTime() / 1000

      if (deadline < time) {
        if (path.indexOf('expired') < 0) {
          path = '/pc/expired'
          next({ path: '/pc/expired', query })
        }
      } else {
        if ((onlineTime < time) && path.indexOf('Prediction') >= 0) {
          path = '/pc'
          next({ path: '/pc', query })
        }
      }

      if (isWeiXin() || isMobile()) {
        if (path === '/') {
          next({ path: '/mb', query })
        } else if (path.indexOf('/pc') !== -1) {
          path = path.replace('/pc', '/mb')
          next({ path: path, query })
        } else {
          next()
        }
      } else {
        if (path === '/') {
          next({ path: '/pc', query })
        } else if (path.indexOf('/mb') !== -1) {
          path = path.replace('/mb', '/pc')
          next({ path: path, query })
        } else {
          next()
        }
      }
    } else {
      let { onlineTime, deadline, time } = store.state.exhibition
      // var time = store.state.exhibition.time
      // console.log(onlineTime, '上线时间')
      onlineTime = new Date(onlineTime).getTime() / 1000

      // console.log(deadline, '展览期限')
      deadline = new Date(deadline).getTime() / 1000

      console.log(time, '现在时间')
      time = new Date(time).getTime() / 1000

      if (deadline < time) {
        if (path.indexOf('expired') < 0) {
          path = '/pc/expired'
          next({ path: '/pc/expired', query })
        }
      } else {
        if ((onlineTime < time) && path.indexOf('Prediction') >= 0) {
          path = '/pc'
          next({ path: '/pc', query })
        }
      }
      if (isWeiXin() || isMobile()) {
        if (path === '/') {
          next({ path: '/mb', query })
        } else if (path.indexOf('/pc') !== -1) {
          path = path.replace('/pc', '/mb')
          next({ path: path, query })
        } else {
          next()
        }
      } else {
        if (path === '/') {
          next({ path: '/pc', query })
        } else if (path.indexOf('/mb') !== -1) {
          path = path.replace('/mb', '/pc')
          next({ path: path, query })
        } else {
          next()
        }
      }
    }
  }
})
