import { GetExhibitionInfo } from '@/network/NewHttpService'
import { createStore } from 'vuex'
import { getWorkList, getStudentList } from '@/network/NewHttpService'
import { useTitle, useFavicon } from '@vueuse/core'

export default createStore({
  state: {
    cosServer: 'https://oss.bodoai.com',
    server: 'https://server.bodoai.com',
    resourcePath: '/file/',
    APPID: 'wx4e5a4dd3d90e4987',
    exhibitionInfo: null,
    exhibition: null, // 展览信息
    // newExhibition: null,
    isLogin: false,
    authorId: 0,
    curAlbum: null,
    wId: 0,
    exhibitionDomainName: '', // 每个展览的唯一简称
    routerWo: '',
    changeAlbum: null,
    top: 0,
    cid: 2,
    eid: 8,
    authorList: [],
    workList: [],
    isOver: false,
    workListParams: {
      totalPage: 0,
      pageNum: 1,
      categoryId: 0,
      teacherId: 0
    },
    isLoading: true,
    navList: null

  },
  getters: {
    measureConfig(state) {
      return state.exhibition?.config.measureConfig
    },
    nameReplaceConfig(state) {
      return state.exhibition?.config.nameReplaceConfig
    },
    functionOpen(state) {
      return state.exhibition?.module
    }
  },
  mutations: {
    setInfo(state, value) {
      state.exhibitionInfo = value
    },
    setExhibitionDomainName(state, value) {
      state.exhibitionDomainName = value
    },
    authId(state, value) {
      state.authorId = value
    },
    setAlbum(state, value) {
      state.curAlbum = value
    },
    editWid(state, value) {
      state.wId = value
    },
    editRouerWo(state, value) {
      state.routerWo = value
    },
    setChangeAlbum(state, value) {
      state.changeAlbum = value
    },
    setExhibition(state, value) {
      state.exhibition = value.exhibit
      state.exhibition.time = value.time
      useTitle(state.exhibition.config.baseConfig.sign)
      useFavicon(state.exhibition.config.baseConfig.logo)
    },

    editScrollTop(state, value) {
      state.top = value
    },
    // 重置请求参数
    clearWorkList(state) {
      state.workList.length = 0
      state.workList = []
      state.workListParams.totalPage = 0
      state.workListParams.pageNum = 1
      state.isOver = false
    },
    // 保存请求参数
    setWorkListParams(state, { totalPage, categoryId, teacherId, pageNum }) {
      state.workListParams.categoryId = categoryId === 0 ? 0 : categoryId
      state.workListParams.teacherId = teacherId === 0 ? 0 : teacherId
      state.workListParams.totalPage = totalPage
      state.workListParams.pageNum = pageNum
      if (pageNum > totalPage) state.isOver = true
    },
    setWorkList(state, list) {
      const _workList = list.map((v, i) => {
        v.coverImg = v.imgUrl
          ? v.imgUrl + '/' + state.exhibition.config.measureConfig.webDirWork
          : 'https://mirror-2022-1259210751.cos.ap-guangzhou.myqcloud.com/system-ui/DefaultUI/work.jpg'
        if (v.creatorImgUrl) {
          v.authorImg = v.creatorImgUrl
        }
        return v
      })
      state.workList = state.workList.concat(_workList)
    },
    setAuthorList(state, value) {
      state.authorList = value
    },
    setNavlist(state, val) {
      state.navList = val.map(e => { return { workId: e.id } })
    },
    setLoader(state, value) {
      state.isLoading = value
    }
  },
  actions: {
    // 保存展览配置信息
    async getExhibitionInfo({ commit, state }) {
      const res = await GetExhibitionInfo(state.exhibitionDomainName)
      commit('setExhibition', { exhibit: res.data.detail.exhibit, time: res.meta.timestamp })
      commit('setInfo', res.data.detail.info)
      return [res.data.detail.exhibit, res.meta.timestamp]
    },

    // 获取作品列表

    async getWorkList({ commit, state }, { pageNum, pageSize = 16, categoryId, teacherId, orderbyzan, orderbyview } = {}) {
      commit('setLoader', true)

      // 把老师或者分类id存进来，用于翻页
      const _pageNum = pageNum || state.workListParams.pageNum
      const totalPage = state.workListParams.totalPage
      const collegeId = state.exhibition.collegeId
      // const _categoryId = categoryId === 0 ? categoryId : categoryId || state.workListParams.categoryId
      // const _teacherId = teacherId === 0 ? teacherId : teacherId || state.workListParams.teacherId
      const _categoryId = categoryId ?? state.workListParams.categoryId
      const _teacherId = teacherId ?? state.workListParams.teacherId
      const _orderbyzan = orderbyzan || 0
      const _orderbyview = orderbyview || 0

      if (totalPage !== 0 && _pageNum > totalPage) {
        commit('setLoader', false)

        state.isOver = true
        return false
      }

      const res = await getWorkList(collegeId, _categoryId, _teacherId, _pageNum, pageSize, _orderbyzan, _orderbyview)
      commit('setWorkListParams', {
        categoryId: _categoryId,
        teacherId: _teacherId,
        totalPage: res.data.pageInfo.pages,
        pageNum: res.data.pageInfo.pageNum + 1
      })
      commit('setWorkList', res.data.pageInfo.list)
      return true
    },

    // 获取学生列表
    async getAuthorList({ commit, state }, { categoryId, teacherId } = {}) {
      const collegeId = state.exhibition?.collegeId
      const _categoryId = categoryId ?? state.workListParams.categoryId
      const _teacherId = teacherId ?? state.workListParams.teacherId
      const asc = { weizhanRank: 'desc' }

      const res = await getStudentList(collegeId, _categoryId, _teacherId, 1, 1000, asc)
      commit('setAuthorList', res.data.pageInfo.list)
    },
    async getNavList({ commit, state }) {
      const res = await getWorkList(state.exhibition.collegeId, 0, 0, 1, 999, 0, 0)
      commit('setNavlist', res.data.pageInfo.list)
    }
  },

  modules: {
  }
})
