import { jssdkInit } from '@/network/NewHttpService'
import wx from 'weixin-js-sdk'
import store from '@/store'
export function getQueryByString(url, name) {
  var params = url.split('?')
  if (params.length > 1) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
    var r = params[1].match(reg)
    if (r != null) {
      return unescape(r[2])
    }
  }
  return null
}
export function isWeiXin() {
  var ua = window.navigator.userAgent.toLowerCase()
  if (ua.indexOf('micromessenger') > -1) {
    return true // 是微信端
  } else {
    return false
  }
}
export function WXShare(detail) {
  wx.config({
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId: detail.appid, // 必填，公众号的唯一标识
    timestamp: detail.timestamp, // 必填，生成签名的时间戳
    nonceStr: detail.nonce, // 必填，生成签名的随机串
    signature: detail.signature, // 必填，签名
    jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
  })
  wx.ready(function() {
    wx.updateAppMessageShareData({
      title: detail.fxTitle,
      desc: detail.fxContent,
      link: detail.fxUrl,
      imgUrl: detail.fxImg
    })
    wx.updateTimelineShareData({
      title: detail.fxTitle,
      desc: detail.fxContent,
      link: detail.fxUrl,
      imgUrl: detail.fxImg
    })
  })
}
export function setWXShare(_title, _desc, _logoUrl) {
  const { baseConfig } = store.state.exhibition.config
  const title = _title ?? baseConfig.title
  const desc = _desc ?? baseConfig.desc
  const url = window.location.href
  const logoUrl = _logoUrl ?? baseConfig.logoUrl
  if (isWeiXin()) {
    jssdkInit(
      title,
      desc,
      url,
      logoUrl
    ).then((res) => {
      WXShare(res.detail)
    })
  }
}
export function tvplayer(idname, vid, pic) {
  // eslint-disable-next-line no-undef
  var video = new tvp.VideoInfo()
  video.setVid(vid)
  // eslint-disable-next-line no-undef
  var player = new tvp.Player()
  player.create({
    video: video,
    autoplay: false,
    pic: pic,
    modId: idname
  })
}
export function isMobile() {
  if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
    return true // 手机端
  } else {
    return false // alert('PC端')
  }
}

export function isIOS() {
  if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    return true // IOS
  } else {
    return false
  }
}
