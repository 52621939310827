import { newRequest } from '../request/NewRequest'

// import qs from 'qs'

export function jssdkInit(title, desc, link, imgUrl) {
  // eslint-disable-next-line no-undef
  return newRequest({
    url: 'https://server.bodoai.com/api/Auth/jssdkInit',
    // eslint-disable-next-line no-undef
    params: { title, desc, link, imgUrl },
    signParams: ({ title, desc, link, imgUrl })
  })
}

/**
 * 获取预览 作品id
 * @param {*} id
 * @returns
 */
export async function getWorkDetailV1(id) {
  return newRequest({
    url: `/exhibit/api/v1/web/getWorkDetail/${id}`,
    signParams: { workId: id }
  })
}
/**
 * 获取部门列表
 * @param {*} collegeId
 * @returns
 */
export async function getDeptList(collegeId) {
  return newRequest({
    url: `/exhibit/api/v1/web/getDeptList/${collegeId}`,
    signParams: { collegeId }
  })
}
/**
 * 获取学校下面所有的教师
 * @param {*} collegeId
 * @returns
 */
export async function getTeacherList(collegeId) {
  return newRequest({
    url: `/exhibit/api/v1/web/getTeacherList/${collegeId}`,
    signParams: { collegeId }
  })
}
/**
 * 获取学生列表
 * @param {} collegeId
 * @param {} deptId
 * @param {*} teacherId
 * @param {*} pageNum
 * @param {*} pageSize
 * @returns
 */
export async function getStudentList(collegeId, deptId, teacherId, pageNum, pageSize) {
  return newRequest({
    url: `/exhibit/api/v1/web/getStudentList/${collegeId}/${deptId}/${teacherId}`,
    params: { pageNum, pageSize },
    signParams: { collegeId, deptId, teacherId, pageNum, pageSize }
  })
}
/**
 * 获取学生作者详情
 * @param {}} studentId
 * @returns
 */
export async function getStudentInfo(studentId) {
  return newRequest({
    url: `/exhibit/api/v1/web/getStudentInfo/${studentId}`,
    signParams: { studentId }
  })
}
export async function NewGetTeacherInfo(teacherId) {
  return newRequest({
    url: `/exhibit/api/v1/web/getTeacherInfo/${teacherId}`,
    signParams: { teacherId }
  })
}

export async function getWorkDetail(workId, imgProcess = 2) {
  return newRequest({
    url: `/exhibit/api/v1/web/getWorkDetail/${workId}`,
    params: { imgProcess },
    signParams: { workId, imgProcess }
  })
}

export async function getWorkList(collegeId, deptId, teacherId, pageNum, pageSize, orderbyzan, orderbyview) {
  return newRequest({
    url: `/exhibit/api/v1/web/getWorkList/${collegeId}/${deptId}/${teacherId}`,
    params: { pageSize, pageNum, orderbyzan, orderbyview },
    signParams: { collegeId, deptId, teacherId, pageNum, pageSize, orderbyzan, orderbyview }
  })
}

export async function NewGetZtWorkList(collegeId, deptId, teacherId, orderbyzan, orderbyview) {
  return newRequest({
    url: `/exhibit/api/v1/web/getWorkList/all/${collegeId}/${deptId}/${teacherId}`,
    params: { orderbyzan, orderbyview },
    signParams: { collegeId, deptId, teacherId, orderbyzan, orderbyview }
  })
}

export async function NewGetGalaxyInfo(collegeId) {
  return newRequest({
    url: `/exhibit/api/v1/web/getGalaxyInfo/${collegeId}`,
    signParams: { collegeId }
  })
}

export async function NewGetExhibitionInfo(exhibitId) {
  return newRequest({
    url: `/exhibit/api/v1/web/getExhibitionInfo/${exhibitId}`,
    signParams: { exhibitId: exhibitId }
  })
}
export async function GetExhibitionInfo(context) {
  return newRequest({
    url: `/exhibit/api/v1/web/getExhibitionInfoContext/${context}`,
    signParams: { context: context }
  })
}
export async function AddGood(workId) {
  return newRequest({
    url: `/exhibit/api/v1/web/addGood/${workId}`,
    signParams: { workId }
  })
}

export async function AddShare(workId) {
  return newRequest({
    url: `/exhibit/api/v1/web/share/${workId}`,
    signParams: { workId }
  })
}

export function exportTemporaryPlan(collegeId, token) {
  return newRequest({
    url: `/exhibit/api/v1/college/exportCollegeText/${collegeId}`,
    method: 'GET',
    signParams: { collegeId },
    responseType: 'blob', /* 解决请求数据乱码问题 */
    headers: {
      Authorization: token
    }
  })
}
