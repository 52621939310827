import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

// 按需 有赞移动端组件
import { Popup, NavBar, Tabbar, TabbarItem, List } from 'vant'

// 轮播图
import 'swiper/css'

// ElementUI组件
// import ElementUI from 'element-plus'
// import 'element-plus/theme-chalk/index.css'

// 懒加载图片
import VueLazyload from 'vue3-lazyload'

// 粘贴板
import VueClipboard from 'vue-clipboard3'

// 挂载全局变量
import VueCookies from 'vue-cookies'

// 图片预览

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

// 瀑布流
import MasonryWall from '@yeger/vue-masonry-wall'

import '@/router/beforeEach.js'

const app = createApp(App)
app.config.globalProperties.$cookies = VueCookies
app.use(router)// 注意顺序
app.use(store)// 注意顺序
app.use(VueViewer, { defaultOptions: { 'navbar': false, toolbar: false, title: false }})
app.use(VueClipboard)
app.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 5,
  loading: 'https://bodo-1259210751.file.myqcloud.com/images/default.gif',
  error: 'https://bodo-1259210751.file.myqcloud.com/images/err2.jpg'
})
app.use(Popup)
app.use(NavBar)
app.use(MasonryWall)
app.use(Tabbar)
app.use(TabbarItem)
app.use(List)
app.mount('#app')
